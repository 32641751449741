import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dsh-seller-progress-bar',
    templateUrl: './seller-progress-bar.component.html',
    styleUrls: ['./seller-progress-bar.component.scss'],
    standalone: false
})
export class SellerProgressBarComponent implements OnInit {
  @Input() visualizations: number = 70;
  @Input() total_clients: number = 7;
  @Input() analyzed_clients: number = 10;
  progress: number = 0;

  ngOnInit() {
    const interval = setInterval(() => {
      if (this.progress < this.visualizations) {
        this.progress += 5;
      } else {
        clearInterval(interval);
      }
    }, 50);
  }
}
