import { Component, Input, OnChanges } from '@angular/core';
import { DeviceService } from 'src/app/core/device.service';
import {
  SellerSellStatistics,
  SellerStatistics,
} from '../../../modules/seller/seller.model';

@Component({
    selector: 'dsh-seller-sell-metrics',
    templateUrl: './dsh-seller-sell-metrics.component.html',
    styleUrls: ['./dsh-seller-sell-metrics.component.scss'],
    standalone: false
})
export class SellerSellMetricsComponent implements OnChanges {
  @Input() sellerSellStatistics!: SellerSellStatistics;
  @Input() sellerStatistics!: SellerStatistics;
  searchedClientsText: string = '';
  notSearchedClientsText: string = '';

  uniqueClientsSoldToText: string = '';
  uniqueProductsCategoriesSoldText: string = '';
  uniqueProductsSoldText: string = '';

  desktop: boolean = false;

  constructor(private readonly deviceService: DeviceService) {
    this.desktop = !this.deviceService.isMobile();
  }

  ngOnChanges() {
    this.searchedClientsText = this.percentageText(
      this.sellerStatistics.uniqueClientsSearched,
      this.sellerStatistics.totalClients
    );
    this.notSearchedClientsText = this.percentageText(
      this.sellerStatistics.clientsNotSearched,
      this.sellerStatistics.totalClients
    );

    this.uniqueClientsSoldToText = this.percentageText(
      this.sellerSellStatistics.uniqueClientsSoldTo,
      this.sellerStatistics.totalClients
    );
    this.uniqueProductsCategoriesSoldText = this.percentageText(
      this.sellerSellStatistics.uniqueProductsCategoriesSold,
      this.sellerSellStatistics.totalCategories
    );
    this.uniqueProductsSoldText = this.percentageText(
      this.sellerSellStatistics.uniqueProductsSold,
      this.sellerSellStatistics.totalProducts
    );
  }

  private readonly percentageText = (number: number, total: number): string => {
    return total > 0
      ? `${number} de ${total} (${Math.round((number * 10000) / total) / 100}%)`
      : '';
  };
}
