import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageKey, StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor(private readonly storageService: StorageService) {}
  initAmplitude(): void {
    if (!environment.amplitud.eneable) {
      return;
    }
    amplitude.init(environment.amplitud.amplitudeKey, {
      autocapture: true,
    });
  }

  trackEvent(eventName: string, eventData?: any): void {
    if (!environment.amplitud.eneable) {
      return;
    }
    this.setUserId();
    this.track(eventName, eventData);
  }

  setUserId() {
    amplitude.setUserId(this.storageService.getData(StorageKey.ClientEmail));
  }

  track(eventName: string, eventData?: any) {
    amplitude.track(eventName, eventData);
  }
}
