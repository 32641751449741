<ng-container>
  <div class="form-group">
    <ng-select
      [items]="items"
      [multiple]="multiple"
      [placeholder]="placeholder"
      [closeOnSelect]="closeOnSelect"
      [searchFn]="customSearchFn"
      (change)="onChange($event)"
      (add)="onAdd($event)"
      (remove)="onRemove($event)"
      (clear)="onClear()"
      [(ngModel)]="selectedItems"
      [disabled]="disabled"
      bindLabel="name"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="onClear">
        <span class="ng-value-label" (click)="onRemove(item)">
          {{ item.name }}</span
        >
        <span
          class="ng-value-icon right"
          (click)="onRemove(item)"
          aria-hidden="true"
          >x</span
        >
      </ng-template>

      <ng-template
        ng-option-tmp
        class="text"
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          class="checkbox"
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        {{ item.name }}
      </ng-template>
    </ng-select>
  </div>
</ng-container>
