<div class="upsert-card">
  <textarea rows="5" class="comment-textarea" [class]="{'margin-bottom': !warning}" placeholder="Adicionar comentário"
            [(ngModel)]="text" [disabled]="!(showNetworkStatus$ | async)"></textarea>
  @if (warning) {
    <span class="warning">{{ warning }}</span>
  }
  <button
    id="send-button"
    mat-raised-button
    color="primary"
    (click)="send()"
    [disabled]="!(showNetworkStatus$ | async) || isImpersonating">
    <span class="send-button">ENVIAR</span>
  </button>
</div>
