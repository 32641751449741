import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCase',
    standalone: false
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    return value
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word
          .split(/([^a-zA-Z0-9])/)
          .map((part) => {
            return /[a-zA-Z]+/.test(part)
              ? part.charAt(0).toUpperCase() + part.slice(1)
              : part;
          })
          .join('');
      })
      .join(' ');
  }
}
