import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Comment } from 'src/app/modules/client/client.model';
import { NetworkService } from '../../../core/network.service';
import {StorageKey, StorageService} from "../../../core/storage.service";

@Component({
    selector: 'app-comment-card',
    templateUrl: './comment-card.component.html',
    styleUrls: ['./comment-card.component.scss'],
    standalone: false
})
export class CommentCardComponent {
  @Input() comment!: Comment;

  @Output() commentEdited = new EventEmitter();
  @Output() commentDeleted = new EventEmitter();

  showNetworkStatus$!: Observable<boolean>;

  isImpersonating: boolean = false

  constructor(
    private readonly networkService: NetworkService,
    private readonly storageService: StorageService
  ) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
    this.isImpersonating = this.storageService.getData(StorageKey.IsImpersonating);
  }

  delete(): void {
    if (!this.isImpersonating) {
      this.commentDeleted.emit(this.comment);
    }
  }

  edit(): void {
    if (!this.isImpersonating) {
      this.commentEdited.emit(this.comment);
    }
  }
}
