import { FrequencyMonth } from './container-frequency/container-frequency.component';

export type typeChart = 'line' | 'stepper' | 'bar' | 'doughnut';

export const ColorChartList: string[] = [
  '#349818',
  '#B0AEAE',
  '#E2CD81',
  '#947BD3',
  '#F26CA7',
  '#ab94e7', // alternative
  '#f1c265', // alternative
  '#d6fad4', // alternative
  '#f787b8', // alternative
  '#F4A392', // alternative
];

export interface MultilineDataset {
  label: string;
  data: number[];
}

export interface ChartData {
  frequency: FrequencyMonth;
  datasets: number[];
}

export interface MultilineChartData {
  frequency: FrequencyMonth;
  datasets: MultilineDataset[];
}

export interface ChartMultiLineData extends MultilineChartData {}
