import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { ClientDetail, Comment } from 'src/app/modules/client/client.model';
import { ClientService } from 'src/app/modules/client/client.service';
import {
  ApplicationInsightsService,
  EventName,
} from '../../../core/application-insights.service';
import { NetworkService } from '../../../core/network.service';
import {StorageKey, StorageService} from "../../../core/storage.service";

@Component({
    selector: 'app-upsert-comment',
    templateUrl: './upsert-comment.component.html',
    styleUrls: ['./upsert-comment.component.scss'],
    standalone: false
})
export class UpsertCommentComponent implements OnInit, OnChanges {
  clientId: string = '';
  client!: ClientDetail;
  text: string = '';
  warning: string = '';

  @Input() comment!: Comment | null;
  @Output() commentUpserted = new EventEmitter();

  showNetworkStatus$!: Observable<boolean>;

  isImpersonating: boolean = false

  constructor(
    private readonly route: ActivatedRoute,
    private readonly clientService: ClientService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly networkService: NetworkService,
    private readonly storageService: StorageService
  ) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
    this.isImpersonating = this.storageService.getData(StorageKey.IsImpersonating);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['comment']) {
      this.text = this.comment ? this.comment.text : '';
    }
  }

  send(): void {
    if (!this.isImpersonating) {
      this.textAreaValidations();
      if (this.warning == '') {
        if (this.comment) {
          this.clientService
            .putComment(this.comment.id.toString(), this.text)
            .pipe(
              tap(() => {
                this.commentUpserted.emit();
                this.comment = null;
                this.text = '';

                this.applicationInsightsService.logEvent(
                  EventName.CommentModification,
                  {
                    client_id: this.clientId,
                    client_name: this.client.legal_name,
                    cnpj: this.client.cnpj,
                  }
                );
              }),
              catchError(() => {
                return EMPTY;
              })
            )
            .subscribe();
        } else {
          this.clientService
            .postComment(this.clientId, this.text)
            .pipe(
              tap(() => {
                this.commentUpserted.emit();
                this.text = '';
                this.applicationInsightsService.logEvent(
                  EventName.CommentCreation,
                  {
                    client_id: this.clientId,
                    client_name: this.client.legal_name,
                    CNPJ: this.client.cnpj,
                  }
                );
              }),
              catchError(() => {
                return EMPTY;
              })
            )
            .subscribe();
        }
      }
    }
  }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId')!;

    this.clientService
      .getClientDetail(this.clientId)
      .pipe(
        tap((client: ClientDetail) => {
          this.client = client;
        })
      )
      .subscribe();
  }

  textAreaValidations() {
    this.warning = '';
    if (this.text == '') this.warning = 'Campo é obrigatório';
  }
}
