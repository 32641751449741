import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {Status} from "../alert-message/alert-message.component";
import {NetworkService} from "../../../core/network.service";
import {Seller} from "../../../modules/seller/seller.model";

@Component({
    selector: 'seller-detail-header',
    templateUrl: './seller-detail-header.component.html',
    styleUrls: ['./seller-detail-header.component.scss'],
    standalone: false
})
export class SellerDetailHeaderComponent {
  @Input() sellerGeneralData!: Seller;

  showNetworkStatus$!: Observable<boolean>;
  offlineStatus: Status = Status.Offline;

  constructor(private readonly networkService: NetworkService) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }
}
