import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  takeUntil,
} from 'rxjs';

@Component({
    selector: 'app-searchbox',
    templateUrl: './searchbox.component.html',
    styleUrls: ['./searchbox.component.scss'],
    standalone: false
})
export class SearchboxComponent {
  showSearchCancel = false;
  @ViewChild('searchInput') input!: ElementRef<HTMLInputElement>;
  @Input() placeholder = 'Buscar por nome ou CNPJ';
  @Output() onEnterInput = new EventEmitter<string>();
  @Output() onKeyupInput = new EventEmitter<string>();
  @Output() onEmptyInput = new EventEmitter<string>();
  @Output() onFocusOutInput = new EventEmitter<string>();
  private unsubscribe$ = new Subject<void>();

  ngAfterViewInit(): void {
    fromEvent<Event>(this.input.nativeElement, 'keyup')
      .pipe(
        map((event) => (event.target as HTMLInputElement).value.toLowerCase()),
        debounceTime(150),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        this.showSearchCancel = !!value;
        this.onKeyupInput.emit(value);
      });

    fromEvent<Event>(this.input.nativeElement, 'keydown')
      .pipe(
        filter((event: any) => Boolean(event?.keyCode === 13)), // enter
        map((event) => (event.target as HTMLInputElement).value.toLowerCase()),
        debounceTime(150),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        this.input.nativeElement.blur();
        this.emitOnEnter(value);
      });

    fromEvent<Event>(this.input.nativeElement, 'blur')
      .pipe(
        map((event) => (event.target as HTMLInputElement).value.toLowerCase()),
        debounceTime(150),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        this.showSearchCancel = false;
        this.onFocusOutInput.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  emitOnEnter(value: string): void {
    this.showSearchCancel = false;
    value ? this.onEnterInput.emit(value) : this.onEmptyInput.emit('');
  }

  clear(value = ''): void {
    this.input.nativeElement.value = value;
    this.showSearchCancel = false;
    this.onEmptyInput.emit(value); // Emit onEmptyInput here
    setTimeout(() => {
      this.input.nativeElement.blur();
    }, 100);
  }
}
