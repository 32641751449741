@if (product) {
  <ng-container>
    <div class="collapsed-content"
         [class.good-review]="feedbackStatus"
         [class.bad-review]="!feedbackStatus"
         [class.hidden]="!(feedbackStatus !== null && !showMoreInfo)"
    >
      <div class="collapsed-title">
        <div class="title">
          {{ product.product_name }}
        </div>
        <div>

          <button
            mat-button
            class="more-info-button"
            (click)="showMoreInfo = !showMoreInfo"
          >
            <mat-icon
              iconPositionEnd
              class="material-icons"
              [svgIcon]="!showMoreInfo ? 'ic-expand-more' : 'ic-expand-less'"
            ></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="item-content" [class.one-priority]="product.sell_priority === 1"
         [class.hidden]="feedbackStatus !== null && !showMoreInfo">
      <div
        class="priority"
        [class.one-priority-title]="product.sell_priority === 1"
      >
        <div>
          Prioridade {{ product.sell_priority }}
        </div>
      </div>

      <div class="row">
        <div class="title">
          {{ product.product_name }}
        </div>
        <button
          mat-button
          class="more-info-button"
          [class.hidden]="feedbackStatus === null"
          (click)="showMoreInfo = !showMoreInfo"
        >
          <mat-icon
            iconPositionEnd
            class="material-icons"
            [svgIcon]="!showMoreInfo ? 'ic-expand-more' : 'ic-expand-less'"
          ></mat-icon>
        </button>
      </div>

      <div class="opportunity">
        <app-progress-bar [progress]="product.sell_oportunity"></app-progress-bar>
      </div>

      <div class="info">
        <div class="chips">
          <div class="chip">
            <span>{{ product.product_category | titleCase }}</span>
          </div>
          @if (product.product_type !== null) {
            <div class="chip">
          <span>{{
              product.product_type === true ? "Com máquina" : "Sem máquina"
            }}</span>
            </div>
          }
        </div>
        @if (showNetworkStatus$ | async) {
          <div class="feedback">
            <app-hands
              [product]="product"
              (handSelect)="handSelectChanged($event)"
            ></app-hands>
          </div>
        }
      </div>
    </div>
  </ng-container>
}
