import { Component } from '@angular/core';
import { LoaderService } from 'src/app/loader.services';
import {Location} from "@angular/common";

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: false
})
export class SpinnerComponent {
  excludedURLs = [
    '/seller/desktop/home',
    '/seller/detail',
    '/seller/list',
    '/client/list',
    '/client/comment',
    '/product/list',
  ];
  isURLExcluded = false;

  constructor(
    public loader: LoaderService,
    public readonly location: Location
  ) {
    this.excludedURLs.forEach((e) => {
      if (this.location.path().startsWith(e))
        this.isURLExcluded = true;
    });
  }
}
