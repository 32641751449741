<div class="row-buttons">
  <div class="subtitle">
    <span id="subtitle">{{ titleChartBar }}</span>
  </div>
  <div class="frequency">
    @for (frequency of frequencies; track $index) {
      <div class="frequency-button">
        <button
          mat-raised-button
          (click)="clickButton(frequency)"
          [class.selected]="selectedFrequency === frequency"
        >
          <span class="text-button">{{ frequency }}</span>
        </button>
      </div>
    }
  </div>
</div>
