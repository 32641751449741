import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationInsightsService,
  EventName,
} from 'src/app/core/application-insights.service';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import {
  ClientItem,
  getCustomerTypeById,
} from 'src/app/modules/client/client.model';

@Component({
    selector: 'app-client-item',
    templateUrl: './client-item.component.html',
    styleUrls: ['./client-item.component.scss'],
    standalone: false
})
export class ClientItemComponent implements OnInit {
  @Input() client?: ClientItem;
  icon!: string;
  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly routerService: Router,
    private readonly storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.icon = getCustomerTypeById(this.client?.customer_type_id!)?.icon;
  }
  openClient(): void {
    this.storageService.removeData(StorageKey.ProductFilter);
    this.applicationInsightsService.logEvent(EventName.RecommendationClick, {
      client_id: this.client?.id,
      client_name: this.client?.name,
      cnpj: this.client?.cnpj,
    });
    this.routerService.navigate(['product/list', this.client?.id]);
  }
}
