import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {NetworkService} from 'src/app/core/network.service';
import {Product} from 'src/app/modules/product/product.model';
import {HandFeedbackOperation} from '../hands/hands.component';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss'],
    standalone: false
})
export class ProductItemComponent implements OnInit{
  @Input() product!: Product;
  @Output() handSelect = new EventEmitter<{
    handFeedbackOperation: HandFeedbackOperation;
    selection: boolean | null;
  }>();
  showNetworkStatus$!: Observable<boolean>;

  showMoreInfo: boolean = false;
  feedbackStatus: boolean | null = null;
  constructor(private readonly networkService: NetworkService) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnInit() {
    if (this.product?.feedback?.state !== undefined) {
      this.feedbackStatus = this.product.feedback.state;
    }
  }

  handSelectChanged(event: any) {
    this.feedbackStatus = event.selection
    this.handSelect.emit(event)
  }
}
