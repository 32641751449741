@if (seller && seller.id) {
<a class="item-seller" (click)="openSeller()">
  <div class="type-logo" [ngClass]="{
  'selected': !isDesktop || (isDesktop && isSelected),
  'unselected': isDesktop && !isSelected

         }">
    <div class="logo-container">
      <mat-icon [inline]="true" class="material-icons logo" [svgIcon]="'dsh-ic-person'">
      </mat-icon>
      <span class="seller-code">{{ seller.code }}</span>
    </div>
  </div>
  <div class="info">
    <span class="title" [ngClass]="{
      'font-size-desktop': isDesktop,
      'selected-title': (isSelected && isDesktop),
      }">{{ seller.name | uppercase }}</span>

    <div class="row">
      <span class="semibold" [ngClass]="isDesktop ? 'font-size-desktop' : ''">Últ. acesso:
        <span class="subtitle" [ngClass]="isDesktop ? 'font-size-desktop' : ''">{{
          seller.lastConnection | date : "dd/MM/yyyy"
          }}</span>
      </span>
      <span class="semibold" [ngClass]="isDesktop ? 'font-size-desktop' : ''">Conexoes mensais:
        <span class="subtitle" [ngClass]="isDesktop ? 'font-size-desktop' : ''">{{ seller.monthlyConnections }}</span>
      </span>
    </div>
  </div>
  <div class="access-logo" [ngClass]="isDesktop ? 'desktop-access-logo' : ''">
    <div class="logo-container">
      <mat-icon [inline]="true" class="material-icons logo" svgIcon="ic-right-circle"
        [svgIcon]="(isSelected && isDesktop) ? 'ic-left-circle' : 'ic-right-circle'">
      </mat-icon>
    </div>
  </div>
</a>
}