<div class="container">
  <div class="row">
    @if (showFrequency) {
      <container-frequency
        (frequencySelected)="frequencySelectedButton($event)"
        [titleChartBar]="titleChartBar"
      ></container-frequency>
    }
  </div>
  <div class="row">
    <chart-bar
      [color]="colorChartBar"
      [wrapperChartData]="wrapperChartData"
      [lineChartData]="lineChartData"
      [frequency]="frequencyChartBar"
      [chartType]="typeChart"
      [heightPx]="chartHeightPx"
      [showLegend]="showLegend"
      [topTooltipBar]="topTooltipBar"
      [percentage]="percentageAxisY"
      [y1Percentage]="percentageAxisY1"
      [xScale]="xScale"
      [yScale]="yScale"
      [y1Scale]="y1Scale"
      [yAxisMultiplier]="yAxisMultiplier"
      [horizontalBar]="horizontalBar"
    ></chart-bar>
  </div>
</div>
