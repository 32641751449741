import {Component, Input} from '@angular/core';

@Component({
    selector: 'dsh-seller-feedback',
    templateUrl: './seller-feedback.component.html',
    styleUrls: ['./seller-feedback.component.scss'],
    standalone: false
})
export class SellerFeedbackComponent {
  @Input() positiveFeedback: string = '';
  @Input() negativeFeedback: string = '';
  @Input() nullFeedback: string = '';

  constructor() {}
}
