import {Component, Input} from '@angular/core';
import {MonthlyConnections, SellerStatistics} from "../../../modules/seller/seller.model";

@Component({
    selector: 'seller-statistics-detail',
    templateUrl: './seller-statistics-detail.component.html',
    styleUrls: ['./seller-statistics-detail.component.scss'],
    standalone: false
})
export class SellerStatisticsDetailComponent {
  @Input() sellerStatistics!: SellerStatistics;

  hasConnections(connections: MonthlyConnections[]) {
    return connections.every((item: any) => item.value === 0);
  }
}
