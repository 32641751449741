import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartOptions } from 'chart.js';
import { isNumber } from 'chart.js/helpers';
import { BaseChartDirective } from 'ng2-charts';
import { ColorChartList, typeChart } from '../chart.model';
import { FrequencyMonth } from '../container-frequency/container-frequency.component';

@Component({
  selector: 'chart-bar',
  templateUrl: './bar.component.html',
  styleUrl: './bar.component.scss',
  standalone: false,
})
export class ChartBarComponent implements OnInit, OnChanges {
  @Input() color!: string;
  @Input() labels!: string[];
  @Input() wrapperChartData!: any;
  @Input() lineChartData!: any;
  @Input() frequency!: FrequencyMonth;
  @Input() chartType!: typeChart;
  @Input() heightPx: string = '190';
  @Input() percentage: boolean = false;
  @Input() showLegend: boolean = false;
  @Input() topTooltipBar: boolean = true;
  @Input() xScale: string = '';
  @Input() yScale: string = '';
  @Input() y1Scale: string = '';
  @Input() y1Percentage: boolean = false;
  @Input() yAxisMultiplier: number = 1;
  @Input() horizontalBar: boolean = false;

  chartDataConfiguration: any = {
    labels: this.labels,
    datasets: [
      {
        data: [],
        label: '',
        borderColor: '#E2F4C3',
        backgroundColor: '#E2F4C3',
        borderRadius: 3,
        order: 2,
        datalabels: {
          align: 'end',
          anchor: 'end',
        },
      },
    ],
  };

  barChartOptions!: ChartOptions<'bar'>;

  barChartLegend = false;

  @ViewChild(BaseChartDirective) chartDirective?: BaseChartDirective;
  @ViewChild('canvasRef') canvasRef?: ElementRef<HTMLCanvasElement>;

  latestPeriod: any;
  lastFourMonths: any;
  last4Weeks: any;

  private originalBarData: any;
  private originalLineData: any;
  constructor() {
    this.setChartOptions();
  }

  ngOnInit(): void {
    this.originalBarData = this.wrapperChartData ? this.wrapperChartData : [];
    this.originalLineData = this.lineChartData ? this.lineChartData : [];
    this.calculateData();
    this.updateChartBarData(this.frequency, this.wrapperChartData);
    this.updateChartColors();

    if (this.lineChartData) {
      this.addChartLineData(this.frequency, this.lineChartData);
    }
    this.setChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['frequency']) {
      this.updateChartBarData(this.frequency, this.wrapperChartData);
      if (this.lineChartData) {
        this.addChartLineData(this.frequency, this.lineChartData);
      }
    }
    if (this.chartDataConfiguration.datasets.length === 1) {
      this.chartDataConfiguration.datasets[0].label =
        this.wrapperChartData[0].client;
    }
    this.updateChartColors();
    this.setChartOptions();
  }
  private calculateData() {
    this.latestPeriod = new Date(
      Math.max(
        ...this.wrapperChartData.chartData.map((d: any) =>
          new Date(d.label).getTime()
        )
      )
    );
    this.lastFourMonths = new Date(
      this.latestPeriod.getFullYear(),
      this.latestPeriod.getMonth() - 4,
      1
    );
    this.last4Weeks = new Date(
      this.latestPeriod.getFullYear(),
      this.latestPeriod.getMonth() - 1,
      1
    );
  }

  ngAfterViewInit() {
    this.updateChartColors();
  }

  private updateChartBarData(frequency: FrequencyMonth, newData: any) {
    if (!newData || newData.chartData.length === 0) {
      return;
    }
    this.filterByFrequency(newData, frequency);
    if (newData.chartData.length > 0) {
      this.chartDataConfiguration.labels = newData.chartData.map(
        (e: any) => e.label
      );
    }

    this.chartDataConfiguration.datasets = [];
    let data: any[] = [];
    newData.chartData.forEach((newDataClient: any) => {
      let value = newDataClient.data;
      if (isNumber(value)) data.push(Math.ceil(value));
    });
    this.chartDataConfiguration.datasets.push({
      data: data,
    });
  }

  private addChartLineData(frequency: FrequencyMonth, newData: any) {
    if (!newData || newData.chartData.length === 0) {
      return;
    }
    this.filterByFrequency(newData, frequency, true);

    let data: any[] = [];
    newData.chartData.forEach((newDataClient: any) => {
      let value = newDataClient.data;
      if (isNumber(value)) data.push(Math.ceil(value));
    });

    this.chartDataConfiguration.datasets.push({
      data: data,
      type: 'bar',
    });
  }

  private updateChartColors() {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        if (this.chartDataConfiguration.datasets.length > 1) {
          this.lineColors();
        } else {
          this.chartDataConfiguration.datasets[0].backgroundColor = this.color;
          this.chartDataConfiguration.datasets[0].borderColor = this.color;
          this.chartDataConfiguration.datasets[0].borderRadius = 8;
          this.chartDataConfiguration.datasets[0].datalabels = {
            display: false,
          };
        }

        if (this.chartDirective) {
          this.chartDirective.update();
        }
      }
    }
  }

  private setChartOptions() {
    const thatComponentContext = this;
    this.barChartOptions = {
      responsive: true,
      scales: {
        y: {
          stacked: false,
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          grace: this.lineChartData ? '100%' : '10%',

          grid: {
            display: true,
            color: 'rgba(55, 53, 53, 0.1)',
            tickBorderDash(ctx, options) {
              return [5, 5];
            },
          },
          ticks: {
            callback(tickValue: any) {
              let value = tickValue * thatComponentContext.yAxisMultiplier;
              if (thatComponentContext.percentage) {
                return value.toLocaleString() + '%';
              }
              if (+value >= 1000000) {
                return (+value / 1000000).toFixed(1) + 'M';
              } else if (+value >= 1000) {
                return (+value / 1000).toFixed(1) + 'K';
              }
              return value.toLocaleString();
            },
          },
          title: {
            color: '#9398a3', //Fifth color
            display: true,
            text: this.yScale,
            font: {
              weight: 'bold',
            },
          },
        },
        //   type: 'linear',
        //   display: !!this.lineChartData,
        //   position: 'right',
        //   beginAtZero: true,
        //   grace: this.lineChartData ? '100%' : '10%',
        //   grid: {
        //     drawOnChartArea: false,
        //     display: false,
        //     color: 'rgba(255,255,255,0.1)',
        //   },
        //   ticks: {
        //     callback(value) {
        //       if (thatComponentContext.y1Percentage) {
        //         return value.toLocaleString() + '%';
        //       }
        //       if (+value >= 1000000) {
        //         return (+value / 1000000).toFixed(1) + 'M';
        //       } else if (+value >= 1000) {
        //         return (+value / 1000).toFixed(1) + 'K';
        //       }
        //       return value.toLocaleString();
        //     },
        //   },
        //   title: {
        //     color: '#9398a3', //Fifth color
        //     display: true,
        //     text: this.y1Scale,
        //     font: {
        //       weight: 'bold',
        //     },
        //   },
        // },
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          title: {
            color: '#9398a3', //Fifth color
            display: true,
            text: this.xScale,
            font: {
              weight: 'bold',
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      plugins: {
        legend: {
          display: this.showLegend,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
          yAlign: 'bottom',
        },
        title: {
          display: false,
        },
      },
    };
  }

  private lineColors() {
    this.chartDataConfiguration.datasets.forEach(
      (dataset: any, index: number) => {
        if (this.lineChartData.chartData && dataset.type === 'line') {
          dataset.label = this.y1Scale;
          dataset.backgroundColor = 'transparent';
          dataset.borderColor = 'grey';
          dataset.radius = 0;
          dataset.borderDash = [5, 5];
          dataset.order = 1;
        } else {
          const colorIndex = index % ColorChartList.length;
          let borderColor: string | string[] = ColorChartList[colorIndex];
          dataset.label = index === 0 ? this.y1Scale : this.yScale;
          dataset.backgroundColor = borderColor;
          dataset.borderColor = borderColor;
          dataset.borderRadius = index === 0 ? 4 : 8;
          dataset.order = index;
        }
      }
    );
  }

  private filterByFrequency(
    newData: any,
    frequency: any,
    lineData: boolean = false
  ) {
    if (!newData || newData.chartData.length === 0) {
      return;
    }
    let originalChart;

    switch (frequency) {
      case FrequencyMonth.ALL:
        originalChart = lineData ? this.originalLineData : this.originalBarData;
        if (originalChart?.chartData) {
          newData.chartData = originalChart.chartData;
        }
        break;
      case FrequencyMonth.Q:
        newData.chartData = newData.chartData.filter((d: any) => {
          const dataDate = new Date(d.label);
          return dataDate >= this.lastFourMonths;
        });
        break;
      case FrequencyMonth.M:
        newData.chartData = newData.chartData.filter((d: any) => {
          const dataDate = new Date(d.label);
          return dataDate >= this.last4Weeks;
        });
        break;
    }
  }
}
