import { Component, Input } from '@angular/core';
export enum Status {
  Warning = 'warning',
  Error = 'error',
  Offline = 'offline',
}
@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
    styleUrls: ['./alert-message.component.scss'],
    standalone: false
})
export class AlertMessageComponent {
  @Input() status: Status = Status.Error;
  statusEnum = Status;
}
