export const environment = {
  production: true,
  serviceWorker: true,
  publicClient: false,
  metrics: true,
  instrumentationKey: 'd6aebf22-ead0-4620-8403-510ea8901d27',

  amplitud: {
    eneable: true,
    amplitudeKey: '3b877d62af99947797dd2bb48669dd4d',
  },
  manuals: {
    access: 'https://sway.cloud.microsoft/s/PD4WC4OPH4SquLUj/embed',
    usability: 'https://sway.cloud.microsoft/s/uwcSEazSAbqduGNt/embed',
  },
  msalConfig: {
    auth: {
      clientId: '7cdaf694-c5f6-410c-8cd5-fccd3228613b',
      authority:
        'https://login.microsoftonline.com/f7dc33ad-6cd1-4c02-a007-59cba6fabb61',
    },
    redirectStartPage: '/client/list',
    sessionStorage: true,
  },
  apiConfig: {
    scopes: ['api://7cdaf694-c5f6-410c-8cd5-fccd3228613b/user_impersonation'],
    uri: 'https://vendasai.basf.com/api/',
  },
};
