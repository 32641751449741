import { Injectable, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiBridgeService } from 'src/app/core/api-bridge.service';
import { NetworkService } from 'src/app/core/network.service';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import { ItemMultiSelect } from 'src/app/shared/components/multiselect/multiselect.component';
import { environment } from 'src/environments/environment';
import {
  ClientDetail,
  ClientItem,
  DefaultClientStatus,
  FilterClient,
} from './client.model';

export enum NgBypass {
  URL = 'ngsw-bypass',
}

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(
    private readonly apiBridge: ApiBridgeService,
    private readonly networkService: NetworkService,
    private readonly storageService: StorageService
  ) {
  }
  clientId = signal('');

  clientsResource = rxResource({ loader: () => this.getClients() });
  clientDetailResource = rxResource({
    request: () => ({id: this.clientId()}),
    loader: ({ request }) => request.id ? this.getClientDetail(request.id) : of(null)
  });
  commentsResource = rxResource({
    request: () => ({id: this.clientId()}),
    loader: ({ request }) => request.id ? this.getComments(request.id) : of(null)
  });

  getClients(): Observable<ClientItem[]> {
    const queryFilters = this.getQueryFilters();
    return this.apiBridge.get(`client?${queryFilters}`);
  }

  setFilter(filters: FilterClient): void {
    this.storageService.saveData(StorageKey.ClientFilters, filters);
  }

  getFilters(): FilterClient | null {
    let filters: FilterClient | null = null;
    if (this.networkService.networkStatusConst) {
      filters = this.storageService.getData(
        StorageKey.ClientFilters
      ) as FilterClient;
    }
    return filters;
  }

  getQueryFilters(): string {
    const objectFilters = this.getFilters();
    if (!objectFilters)
      return environment.publicClient
        ? ''
        : `status=${DefaultClientStatus.text}`;

    const { states, cities, neighborhoods, selectedOption } = objectFilters;
    const queryParams: string[] = [];

    if (Array.isArray(states) && states.length > 0) {
      queryParams.push(
        `states=${states.map((x: ItemMultiSelect) => x.id).join(',')}`
      );
    }
    if (Array.isArray(cities) && cities.length > 0) {
      queryParams.push(
        `cities=${cities.map((x: ItemMultiSelect) => x.id).join(',')}`
      );
    }
    if (Array.isArray(neighborhoods) && neighborhoods.length > 0) {
      queryParams.push(
        `neigh=${neighborhoods.map((x: ItemMultiSelect) => x.id).join(',')}`
      );
    }
    if (
      environment.publicClient &&
      typeof selectedOption === 'number' &&
      selectedOption !== 3
    ) {
      queryParams.push(`status=${selectedOption === 2 ? 'public' : 'private'}`);
    } else {
      queryParams.push(`status=${DefaultClientStatus.text}`);
    }

    return queryParams.join('&');
  }

  getClientDetail(clientId: string): Observable<ClientDetail> {
    return this.apiBridge.get(`client/${clientId}/detail`);
  }

  getStates(status?: string): Observable<ItemMultiSelect[]> {
    let queryString = '';
    if (status) {
      queryString = `?client_status=${status}`;
    }
    return this.apiBridge.get(`state${queryString}`);
  }

  getCities(
    states: ItemMultiSelect[],
    status?: string
  ): Observable<ItemMultiSelect[]> {
    const stateIds = states.map((state: ItemMultiSelect) => state.id).join(',');
    let query = `states=${stateIds}`;
    if (status) {
      query += `&client_status=${status}`;
    }
    return this.apiBridge.get(`city?${query}`);
  }

  getNeighborhoods(
    cities: ItemMultiSelect[],
    status?: string
  ): Observable<ItemMultiSelect[]> {
    const cityIds = cities.map((city: ItemMultiSelect) => city.id).join(',');
    let query = `cities=${cityIds}`;

    if (status) {
      query += `&client_status=${status}`;
    }

    return this.apiBridge.get(`neighborhood?${query}`);
  }

  getComments(clientId: string): Observable<Comment[]> {
    return this.apiBridge
      .get('comment', {
        client_id: clientId,
        [NgBypass.URL]: true,
      })
      .pipe(
        map((response) => response as Comment[]),
        catchError(() => {
          return of([]);
        })
      );
  }

  postComment(clientId: string, comment: string): Observable<Comment> {
    return this.apiBridge.post('comment', {
      client_id: clientId,
      text: comment,
    });
  }

  putComment(commentId: string, comment: string): Observable<Comment> {
    return this.apiBridge.put(`comment/${commentId}`, {
      text: comment,
    });
  }

  deleteComment(commentId: string): Observable<any> {
    return this.apiBridge.delete(`comment/${commentId}`);
  }
}
